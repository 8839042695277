import React from "react";
import { MainPage, Footer } from "./components";
import "./main.css";

export default function App() {
  return (
    <>
      <MainPage />
      <Footer />
    </>
  );
}
